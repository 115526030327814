import { Injector } from '@angular/core';
import { ReportTour } from '@app/modules/reports/components/tour-steps/reports.steps';
import { TranslateService } from '@ngx-translate/core';
import { IStepOption } from 'ngx-ui-tour-ng-bootstrap';


export class VisitTypesConfigTour{
  constructor(public translateService: TranslateService){ 
  }
  steps = (): IStepOption[] => {
    return [
      {
        anchorId: 'title.visit.types',
        route: '/dealer-visit-types',
        content:this.translateService.instant('Tour Message Content Visit Types'),
        placement: 'bottom',
        title: '',
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'filter.visit.types',
        route: '/dealer-visit-types',
        content:
          this.translateService.instant('Tour Message Content Filter'),
        placement: 'bottom',
        title: this.translateService.instant('Filter'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'button.visit.types',
        route: '/dealer-visit-types',
        content: this.translateService.instant('Tour Message Button Visit Types'),
        placement: 'bottom',
        title: `${this.translateService.instant('Register')} ${this.translateService.instant('Entry Type')}`,
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'create.visit.types',
        route: '/dealer-visit-types/create',
        content: this.translateService.instant('Tour Message Create Visit Types'),
        placement: 'bottom',
        title: `${this.translateService.instant('Register')} ${this.translateService.instant('Entry Type')}`,
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
        isOptional: true,
      },
      {
        anchorId: 'create.visit.types.flow',
        route: '/dealer-visit-types/create',
        content: this.translateService.instant('Tour Message Create Visit Types Flows'),
        placement: 'right',
        title: `${this.translateService.instant('Register')} ${this.translateService.instant('Entry Type')}`,
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
        isOptional: true,
      },
    ];
  }
}

export class TemporaryExitsTour{
  constructor(public translateService: TranslateService){ 
  }
  steps = (): IStepOption[] => {
    return [
      {
        anchorId: 'allowTemporaryExits',
        route: '/visits/temporary-exits',
        content: this.translateService.instant('Tour Message Allow Temporary Exit'),
        placement: 'top',
        title: this.translateService.instant('Temporary Exits'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isAsync: true,
        isOptional: true,
      },
    ];
  }
}
export class PlateAndChassiLogsTour{
  constructor(public translateService: TranslateService){ 
  }
  steps = (): IStepOption[] => {
    return [
      {
        anchorId: 'title.change.plate.logs',
        route: '/visits/logs',
        content: this.translateService.instant('Tour Message Content Visit Changes Logs'),
        placement: 'bottom',
        title: this.translateService.instant('Plate/Chassi Change Logs'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'title.view.all.logs',
        route: '/visits/logs/null',
        content: this.translateService.instant('Tour Message Content Visit Changes Log'),
        placement: 'bottom',
        title: this.translateService.instant('Plate/Chassi Change Logs'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
    ];
  }
}

export class VehiclePhotosTour{
  constructor(public translateService: TranslateService){ 
  }
  steps = (): IStepOption[] => {
    return [
      {
        anchorId: 'title.vehicle.photos',
        route: '/visits/photos',
        content: this.translateService.instant('Tour Message Content View Vehicle Photos'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title View Vehicle Photos'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'button.vehicle.photos',
        route: '/visits/photos',
        content: this.translateService.instant('Tour Message Content One View Search'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title View Vehicle Photos'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'icon.vehicle.photos',
        route: '/visits/photos',
        content: this.translateService.instant('Tour Message Icon View Vehicle Photos'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title View Vehicle Photos'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
    ];
  }
}


export class ManagerWorkshopTour{
  constructor(public translateService: TranslateService){ 
  }
  steps = (): IStepOption[] => {
    return [
      {
        anchorId: 'title.visits',
        route: '/visits',
        content: this.translateService.instant('Tour Message Content Manager Visits'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Visits'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'filter.visits',
        route: '/visits',
        content: this.translateService.instant('Tour Message Content Manager Filter'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Filter'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'date.range.filter',
        route: '/visits',
        content: this.translateService.instant('Tour Message Content Date Filter'),
        placement: 'bottom-after',
        title: this.translateService.instant('Tour Message Title Date Filter'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        enableBackdrop: false,
        isOptional: true,
      },
      {
        anchorId: 'release.visits',
        route: '/visits',
        content: this.translateService.instant('Tour Message Content Cancel Release'),
        placement: 'before-bottom',
        title: this.translateService.instant('Tour Message Title Cancel Release'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
        isOptional: true,
      },
    ];
  }
}



export class ManagerReleasedTour{
  constructor(public translateService: TranslateService){ 
  }
  steps = (): IStepOption[] => {
    return [
      {
        anchorId: 'title.released',
        route: '/visits/day-outs',
        content: this.translateService.instant('Tour Message Content Day Outs'),
        placement: 'bottom-after',
        title: this.translateService.instant('Day Outs'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
      {
        anchorId: 'filter.released',
        route: '/visits/day-outs',
        content: this.translateService.instant('Tour Message Content Manager Filter'),
        placement: 'bottom',
        title: this.translateService.instant('Tour Message Title Filter Day Outs'),
        nextBtnTitle: this.translateService.instant('Next'),
        prevBtnTitle: this.translateService.instant('Previous'),
        endBtnTitle: this.translateService.instant('Finalize'),
      },
    ];
  }
}

export class ManagerTour{
  constructor(public translateService: TranslateService){ 
  }
  steps = (): IStepOption[] => {
    var managerTourSteps: IStepOption[] = [];
    var visitTypesConfigTour: VisitTypesConfigTour = new VisitTypesConfigTour(this.translateService);
    var temporaryExitsTour: TemporaryExitsTour = new TemporaryExitsTour(this.translateService);
    var plateAndChassiLogsTour: PlateAndChassiLogsTour = new PlateAndChassiLogsTour(this.translateService);
    var vehiclePhotosTour: VehiclePhotosTour = new VehiclePhotosTour(this.translateService);
    var managerWorkshopTour: ManagerWorkshopTour = new ManagerWorkshopTour(this.translateService);
    var managerReleasedTour: ManagerReleasedTour = new ManagerWorkshopTour(this.translateService);
    var reportsTour: ReportTour = new ReportTour(this.translateService);
    /**
     * Users Configuration (ONE VIEW)
     */
    managerTourSteps = managerTourSteps.concat({
      anchorId: 'title.users',
      route: '/users',
      content: this.translateService.instant('Tour Message Content Users'),
      placement: 'right',
      title: this.translateService.instant('Tour Message Title Users'),
      nextBtnTitle: this.translateService.instant('Next'),
      prevBtnTitle: this.translateService.instant('Previous'),
      endBtnTitle: this.translateService.instant('Finalize'),
    })

    /**
     * Visit types configuration
     */
    managerTourSteps = managerTourSteps.concat(visitTypesConfigTour.steps());
    /**
     * Temporary exits configuration
     */
    managerTourSteps = managerTourSteps.concat(temporaryExitsTour.steps());
    /**
     * Plate and Chassi Logs
     */
    managerTourSteps = managerTourSteps.concat(plateAndChassiLogsTour.steps());
    /**
     * Vehicle Photos
     */
    managerTourSteps = managerTourSteps.concat(vehiclePhotosTour.steps());

    /**
     * Workshop
     */
    managerTourSteps = managerTourSteps.concat(managerWorkshopTour.steps());

    /**
     * Released
     */
    managerTourSteps = managerTourSteps.concat(managerReleasedTour.steps());

    /**
     * Reports
     */
    managerTourSteps = managerTourSteps.concat(reportsTour.steps());

    return managerTourSteps;
  }
}