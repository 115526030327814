import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@app/services/base.service';
import { PagedResponse } from '@app/services/griddatasource.service';
import { environment } from '@env/environment';
import { Vehicle } from 'diagnostic-web-components/types/Vehicle';
import { Observable, map, catchError, of } from 'rxjs';
import { VehicleClass } from '../models/vehicle-class';

@Injectable({
  providedIn: 'root'
})
export class VehicleClassService extends BaseService {
  private httpAnonymous: HttpClient;

  constructor(private http: HttpClient, handler: HttpBackend) {
    super();
    this.httpAnonymous = new HttpClient(handler);
  }

  getVehicleClasses(filter: string, page: number, pageSize: number): Observable<PagedResponse<VehicleClass>> {
    return this.http
      .get<PagedResponse<VehicleClass>>(
        `${environment.technicalDeliveryServerUrl}/vehicle-classes?Page=${page}&PageSize=${pageSize}&Filter=${filter}`
      )
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  getAllVehicleClasses(): Observable<VehicleClass[]> {
    return this.http
      .get<VehicleClass[]>(
        `${environment.technicalDeliveryServerUrl}/vehicle-classes/all`
      )
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  postVehicleClass(vehicleClass : VehicleClass) : Observable<VehicleClass>{
    return this.http
      .post<VehicleClass>(`${environment.technicalDeliveryServerUrl}/vehicle-classes`, vehicleClass)
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  putVehicleClass(id: number, vehicleClass: VehicleClass) : Observable<VehicleClass>{

    return this.http
      .put<VehicleClass>(`${environment.technicalDeliveryServerUrl}/vehicle-classes/${id}`, vehicleClass)
      .pipe(map(super.extract), catchError(super.serviceError));

  }

  getVehicleClass(id:any) : Observable<VehicleClass>{
    return this.httpAnonymous
      .get<VehicleClass>(`${environment.technicalDeliveryServerUrl}/vehicle-classes/${id}`)
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  deleteClass(id: number) : Observable<VehicleClass>{
    return this.http
      .delete<VehicleClass>(`${environment.technicalDeliveryServerUrl}/vehicle-classes/${id}`)
      .pipe(map(super.extract), catchError(super.serviceError));
  }
}
