import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from '@azure/msal-browser';
import {
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { environment } from '@env/environment';


const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}


export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      redirectUri: environment.redirectUrl,
      postLogoutRedirectUri: environment.postLogoutRedirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: environment.msalLogLevel,
        piiLoggingEnabled: true,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.msGraphUrl, ['https://graph.microsoft.com/.default']);
  protectedResourceMap.set(environment.serverUrl, ['profile', 'openid', `https://volvogroup.onmicrosoft.com/${environment.clientId}/user.read`]);
  protectedResourceMap.set(environment.technicalDeliveryServerUrl, ['profile', 'openid', `https://volvogroup.onmicrosoft.com/${environment.clientId}/user.read`]);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: DwellAuthScopes(),
    },
    loginFailedRoute: '/error'
  };
}

export function DwellAuthScopes(): string[]{
  return ['profile', 'openid', `https://volvogroup.onmicrosoft.com/${environment.clientId}/user.read`, 'user.read'];
}

export function GraphAuthScopes(): string[]{
  return ['https://graph.microsoft.com/.default'];
}