import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MonitoringService } from '@app/services/logging.service';
import { MsalService } from '@azure/msal-angular';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { DiagGdspHandler } from 'diagnostic-web-components/diag-gdsp-handler';
import { from, map, of, shareReplay } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { DwellAuthScopes } from '../configs/auth-config';
import { RoleEnum } from '../enum/role.enum';
import { Credentials } from '../models/credentials.model';

export const credentialsKey = 'credentials';

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  public profile$: Observable<Credentials>;
  
  public _credentials: Credentials | null = null;
  private _displayName: Observable<string>;
  public get displayName(): Observable<string> {
    return this._displayName;
  }
  public set displayName(value: Observable<string>) {
    this._displayName = value;
  }

  public get $credentials(): Observable<Credentials>{
    return of(this._credentials as Credentials);
  }

  private _dealerGroupName: string;
  public set dealerGroupName(value: string) {
    this._dealerGroupName = value;
  }
  getDealerGroupName(): Observable<string>{
    return of(this._dealerGroupName);
  }
  private _dealerName: string;
  public set dealerName(value: string) {
    this._dealerName = value;
  }
  getDealerName(): Observable<string>{
    return of(this._dealerName);
  }

  constructor(private monitorService: MonitoringService, private http: HttpClient, private translateService: TranslateService,public _msalService: MsalService) {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
    }
  }

  getProfile(): Observable<Credentials> {
    return this.http.get<Credentials>(`${environment.serverUrl}/Profile`, { headers: new HttpHeaders().append('ignoreSelectedRole', 'true') }).pipe(
      map(response => response),
      shareReplay()
    );
  }

  createDiagGdspHandler(): Observable<DiagGdspHandler>{
    const dwellAccessTokenRequest = {
      scopes: DwellAuthScopes(),
      account: this._msalService.instance.getAllAccounts()[0],
      authority: environment.authority
    };


    const token = from(this._msalService.instance.acquireTokenSilent(dwellAccessTokenRequest));

    return token.pipe(map(profile=> {
      return new DiagGdspHandler({ 
        appApiKey: environment.gdspApiKey,
        apiBaseUrl: environment.gdspApiUrl,
        tokenManager: {
          token: profile.accessToken
      } as any })
    }))
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  get mustHideLoggedRole():Observable<boolean> {
    let hasSelected: boolean = JSON.parse(localStorage.getItem('hasSelectedRole') as string)
    return of(!hasSelected);
  };

  get getActualRole(): Observable<string>{
    return of(this.translateService.instant(this.extractRoleName(this._credentials?.role as number)));
  }
  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }

  get token(): string {
    return this._credentials?.api_access_token as string;
  }
  setScheduleNoShowTime(time: string){
    (this._credentials as Credentials).scheduleNoShowTime = time;
  }

  setTemporaryExitsConfiguration(allow: boolean) {
    (this._credentials as Credentials).allowTemporaryExit = allow;
  }
  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: any, remember?: boolean) {
    this._credentials = credentials || null;

    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }

  getReleaseType(): string {
    if (this.credentials?.role === RoleEnum.Workshop) {
      return 'Confirm Release to Gate';
    }

    if (this.credentials?.role === RoleEnum.Instructor) {
      return 'Confirm Release to Gate';
    }

    if (this.credentials?.role === RoleEnum.Gate) {
      return `Confirm Release`;
    }

    if (this.credentials?.role === RoleEnum.Counter) {
      return `Confirm Release to Gate`;
    }

    if (this.credentials?.role === RoleEnum.Manager) {
      return `Return Release to Workshop`;
    }

    return '';
  }

  public mustAccess(role: number[]): boolean {
    if (role.indexOf(this._credentials?.role as number) > -1) return false;

    return true;
  }

  extractRoleIcon(role: number): string{
    switch (role) {
      case RoleEnum.Manager:
        return "fas fa-briefcase";
      case RoleEnum.Counter:
        return "fas fa-calculator"
      case RoleEnum.Workshop:
        return "fas fa-tools"
      case RoleEnum.ReportViewer:
        return "fas fa-tachometer-alt"
      case RoleEnum.Administrator:
        return "fas fa-tools"
      case RoleEnum.CelulaAtiva:
        return "fas fa-calendar-check"
      case RoleEnum.Gate:
        return "fas fa-door-closed"
      case RoleEnum.Instructor:
        return "fas fa-chalkboard-teacher"
      case RoleEnum.Monitor:
        return "fas fa-tv"
      case RoleEnum.OneView:
        return "fas fa-desktop"

      default:
        return ""
    }
  }
  extractRoleName(role: number): string {
    if (role === RoleEnum.Administrator) {

      return 'Administrator'
    }
    if (role === RoleEnum.Counter) {
      return 'Cashier';
    }
    if (role === RoleEnum.Gate) {
      return 'Gate';
    }
    if (role === RoleEnum.Workshop) {
      return 'Workshop';
    }
    if (role === RoleEnum.ReportViewer) {
      return 'Power BI';
    }
    if (role === RoleEnum.Manager) {
      return 'Manager';
    }

    if (role === RoleEnum.CelulaAtiva) {
      return 'Active Cell';
    }

    if (role === RoleEnum.Instructor) {
      return 'Instructor';
    }

    if (role === RoleEnum.Monitor) {
      return 'Monitor';
    }

    if (role === RoleEnum.Integration) {
      return 'Integration';
    }

    if (role === RoleEnum.OneView) {
      return 'One View';
    }

    return 'Não Cadastrado';
  }

  public async validateRedirect(role: number): Promise<string> {
    switch (role) {
      case RoleEnum.Manager:
        return Promise.resolve('/visits');
      case RoleEnum.Counter:
        return Promise.resolve('/visits');
      case RoleEnum.Workshop:
        return Promise.resolve('/visits');
      case RoleEnum.ReportViewer:
        return Promise.resolve('/reports');
      case RoleEnum.Administrator:
        return Promise.resolve('/users');
      case RoleEnum.CelulaAtiva:
        return Promise.resolve('/scheduling');
      case RoleEnum.Gate:
        return Promise.resolve('/visits/create#entrance');
      case RoleEnum.Instructor:
        return Promise.resolve('/technical-delivery');
      case RoleEnum.Monitor:
        return Promise.resolve('/monitor');
      case RoleEnum.OneView:
        return Promise.resolve('/workshop/one-view');

      default:
        return Promise.resolve('/home');
    }
  }
}
