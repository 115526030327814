<ngx-loading-bar id="app-loading-bar" name="app-component-spinner" color="#042375" height="3px"></ngx-loading-bar>
<div [hidden]="valid" class="div-card-principal">
  <app-language-selector></app-language-selector>
  <div class="row">
    <div style="margin-bottom: 10px;">
      <button style="margin-right: 100px; margin-top: 90px;" class="btn btn-outline-primary btn-sm float-end" type="click"
        (click)="onCreateChecklistPopUp()">
        {{ 'Checklist' | translate }}
      </button>
    </div>
    <div style='position: relative; height: 100%;'>
      <pdf-viewer
          *ngIf="!isMobile"
          [src]="pdfSource"
          [render-text]="true"
          [original-size]="true"
          [zoom]="1.5"
    ></pdf-viewer>
    <pdf-viewer
      *ngIf="isMobile"
      (after-load-complete)="onScroll()"
      [src]="pdfSource"
      [render-text]="true"
      [show-all]="true"
      [original-size]="false"
      [autoresize]="true"
      ></pdf-viewer>
    </div>
    <label [hidden]="!hasLinkedVehicles" class="form-check-label" for="check-linked-vehicles">
      <input class="form-check-input" [(ngModel)]="checkLinkedVehicles" (change)="onChangeCheckReadTerms()" type="checkbox" id="check-linked-vehicles">
      {{('Accept LinkedVehicle' | translate) + linkedVehicleString}}</label>
    <label class="form-check-label" for="check-read-terms">
      <input class="form-check-input" [(ngModel)]="checkReadTerms" (change)="onChangeCheckReadTerms()" type="checkbox" id="check-read-terms">
      {{'Have Read Terms' | translate}}</label>
    <div class="text-center">
        <button [disabled]="disableAccept" [ngClass]="btnClass" type="button" (click)="onAccept()">
          {{ 'Accept' | translate }}
        </button>
    </div>
  </div>
</div>
<div [hidden]="getCheckList">
    <div id="content" #content>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'Create Checklist' | translate }}</h4>
        <button type="button" class="btn-close" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <input [checked]="Response<0" [disabled]="true" type="checkbox" id="client-checklist">
        <label for="client-checklist">{{'Customer will not proceed with the technical delivery checklist' | translate}}</label>
        <div class="table-responsive-md">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col" sortable="item" width="10px">{{ 'Item' | translate }}</th>
                <th scope="col" sortable="Descricao">{{ 'Description' | translate }}</th>
                <th scope="col" sortable="">{{ '#' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let checklist of checkListItens; index as i">
                <td class="item-index">
                  <ngb-highlight [result]="(i+1).toString()" term=""></ngb-highlight>
                </td>
                <td>
                  <ngb-highlight [result]="checklist.description" term=""></ngb-highlight>
                </td>
                <td>
                  <input [checked]="checklist.marked" [disabled]="true" class="checkbox-item" id="checklistcheckbox" type="checkbox">
                </td>
              </tr>
              <tr [hidden]="checkListItens !== undefined">
                <td colspan="6">{{'No Data' | translate}}</td>
              </tr>
            </tbody>
          </table>

        </div>
        <input [checked]="Response>0" [disabled]="true" type="checkbox" id="checklist-finish">
        <label for="checklist-finish">{{'All items have been checked off by the customer' | translate}}</label>
      </div>
    </div>
    <div class="modal-footer checklist-footer d-flex justify-content-between">
      <button id="print-page-button" type="button" class="btn btn-primary print-page-button" translate>Print</button>
    </div>
</div>



