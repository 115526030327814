import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PageHeaderComponent } from './component/page-header/page-header.component';
import { RouterModule } from '@angular/router';
import { PageFooterComponent } from './component/page-footer/page-footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreadCrumbComponent } from './component/bread-crumb/bread-crumb.component';
import { FormFieldErrorComponent } from './component/form-field-error/form-field-error.component';
import { ClipboardModule } from 'ngx-clipboard';
import {IMaskModule} from 'angular-imask';

import { Select2Module } from 'ng-select2-component';
import { DateRangeFilterComponent } from './component/date-range-filter/date-range-filter.component';
import { CameraComponent } from './component/camera/camera.component';
import { WebcamModule } from 'ngx-webcam';
import { DpDatePickerModule } from 'ng2-date-picker';
import { TourNgBootstrapModule } from 'ngx-ui-tour-ng-bootstrap';
import { KmPipe } from './utils/km.pipe';
import { BlockCopyPasteDirective } from './directives/block-copy-paste.directive';
import { TagInputModule } from 'ngx-chips';
import { VisitTypesFilterComponent } from './component/visit-types-filter/visit-types-filter.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TableCounterComponent } from './component/table-counter/table-counter.component';
import { RoleFilterComponent } from './component/role-filter/role-filter.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DealersFilterComponent } from './component/dealers-filter/dealers-filter.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ActualStagesFilterComponent } from './component/actual-stages-filter/actual-stages-filter.component';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { SelectVehicleComponent } from './component/select-vehicle/select-vehicle.component';
import { ThisWorkshopComponent } from './component/vehiclesEntry/this-workshop/this-workshop.component';
import { OtherWorkshopComponent } from './component/vehiclesEntry/not-matching-plate-chassi/not-matching-plate-chassi.component';
import { OtherDealershipChassiComponent } from './component/vehiclesEntry/other-dealership-chassi/other-dealership-chassi.component';
import { OtherDealershipPlateComponent } from './component/vehiclesEntry/other-dealership-plate/other-dealership-plate.component';

@NgModule({
  imports: [
    NgxMaskDirective,
    NgxMaskPipe,
    TranslateModule,
    CommonModule,
    RouterModule,
    FormsModule,
    NgbModule,
    IMaskModule,
    SweetAlert2Module,
    NgMultiSelectDropDownModule,
    WebcamModule,
    DpDatePickerModule,
    TourNgBootstrapModule,
    TagInputModule,
    NgSelectModule
  ],
  declarations: [
    PageHeaderComponent,
    PageFooterComponent,
    BreadCrumbComponent,
    FormFieldErrorComponent,
    DateRangeFilterComponent,
    CameraComponent,
    KmPipe,
    BlockCopyPasteDirective,
    VisitTypesFilterComponent,
    DealersFilterComponent,
    TableCounterComponent,
    RoleFilterComponent,
    ActualStagesFilterComponent,
    SelectVehicleComponent,
    ThisWorkshopComponent,
    OtherWorkshopComponent,
    OtherDealershipChassiComponent,
    OtherDealershipPlateComponent
  ],
  exports: [
    NgxMaskDirective,
    NgxMaskPipe,
    PageHeaderComponent,
    PageFooterComponent,
    BreadCrumbComponent,
    TableCounterComponent,
    CameraComponent,
    FormFieldErrorComponent,
    DateRangeFilterComponent,
    VisitTypesFilterComponent,
    DealersFilterComponent,
    TranslateModule,
    ActualStagesFilterComponent,
    ClipboardModule,
    DpDatePickerModule,
    SweetAlert2Module,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    IMaskModule,
    Select2Module,
    TourNgBootstrapModule,
    KmPipe,
    BlockCopyPasteDirective,
    TagInputModule,
    RoleFilterComponent,
    SelectVehicleComponent
  ],
})
export class SharedModule {}
