import { DatePipe, DecimalPipe, UpperCasePipe } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandler, LOCALE_ID } from "@angular/core";
import { RouteReuseStrategy } from "@angular/router";
import { MsalService, MsalGuard, MsalBroadcastService, MsalInterceptor, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from "@azure/msal-angular";
import { NgbDateAdapter, NgbDateParserFormatter, NgbTimeAdapter } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { MSALInstanceFactory, MSALGuardConfigFactory, MSALInterceptorConfigFactory } from "./core/auth/configs/auth-config";

import { ErrorHandlerService } from "./services/error-handler.service";
import { MonitoringService } from "./services/logging.service";
import { ApiPrefixInterceptor, ErrorHandlerInterceptor, RouteReusableStrategy } from "./shared";
import { LanguageInterceptor } from "./shared/http/language.interceptor";
import { RoleInterceptor } from "./shared/http/role.interceptor";
import { TokenInterceptor } from "./shared/http/token.interceptor";
import { CustomAdapter, CustomDateParserFormatter } from "./shared/utils/datepicker-adapter";
import { NgbTimeStringAdapter } from "./shared/utils/timepicker-adapter";
import {provideNgxMask} from 'ngx-mask';


export var providers = [
  provideNgxMask(),
  NgxSpinnerService,
  DatePipe,
  { provide: LOCALE_ID, useValue: 'pt' },
  { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LanguageInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RoleInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiPrefixInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorHandlerInterceptor,
    multi: true,
  },
  {
    provide: RouteReuseStrategy,
    useClass: RouteReusableStrategy,
  },
  {
    provide: NgbDateAdapter,
    useClass: CustomAdapter,
  },
  {
    provide: NgbDateParserFormatter,
    useClass: CustomDateParserFormatter,
  },
  {
    provide: ErrorHandler,
    useClass: ErrorHandlerService,
  },
  { provide: MonitoringService },
  { provide:UpperCasePipe },

  //msal
  {provide: MsalService },
  {provide: MsalGuard },
  {provide: MsalBroadcastService },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true,
  },

  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory,
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory,
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory,
  },
]