import { Component, ComponentFactoryResolver, ElementRef, EventEmitter, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VehicleCheckListItem } from '@app/modules/technical-delivery/models/vehicle-checklist';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
import domtoimage from 'dom-to-image';
import  jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { NgxSpinnerService } from 'ngx-spinner';
import { VehicleChecklistService } from '@app/modules/technical-delivery/services/vehicle-checklist.service';
import { ImageComponent } from '@app/modules/visits/components/photos/image/image.component';

@Component({
  selector: 'app-contract-checklist',
  templateUrl: './contract-checklist.component.html',
  styleUrls: ['./contract-checklist.component.scss']
})

export class ContractChecklistComponent implements OnInit {
  @Input() CheckItem: VehicleCheckListItem[] | null;
  @Input() Response: number;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;
  disableCheck = false;
  disableUncheck = false;
  checkedList:VehicleCheckListItem[] = [];
  hideModal = false;

  constructor (public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    public checklistService: VehicleChecklistService,
    private modalService: NgbModal,
    ) { }

  ngOnInit() {
  }

  printPage() {
    window.print();
  }

  checkResponse(){
    if(this.Response > 0)this.onCheckAll();
    if(this.Response < 0)this.onUncheckAll();
  }

  onUncheckAll(){
    this.CheckItem?.forEach(element => {
      element.marked = false;
    });
    this.disabled = !this.disabled;
    this.disableCheck = !this.disableCheck;
  }

  onCheckAll(){
    this.CheckItem?.forEach(element => {
      element.marked = true;
    });
    this.disabled = !this.disabled;
    this.disableUncheck = !this.disableUncheck;
  }

  openImage(checklistItem: VehicleCheckListItem) {
    this.spinner.show('checkListItemsSpinner');
    this.checklistService.getVehicleCheckListItemImage(checklistItem.id as number).subscribe(res =>{
      const modalRef = this.modalService.open(ImageComponent, { size: 'md', centered: true });
      modalRef.componentInstance.vehicleSide = `Item Image ${checklistItem.position}: ${checklistItem.description}`
      modalRef.componentInstance.image = res.url;
      this.spinner.hide('checkListItemsSpinner');
    })
  }
}
