import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsService } from '@app/core/auth';
import { Credentials } from '@app/core/auth/models/credentials.model';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
const credentialsKey = 'credentials';
import { lastValueFrom, Observable } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  loginDisplay = false;
  roles: any[] = [];
  constructor(
    private _msalService: MsalService,
    public credentialsService: CredentialsService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}


  ngOnInit(): void {
    localStorage.setItem('hasSelectedRole', 'false');
    this.getProfile();
  }

  async getProfile() {
    const profile = await lastValueFrom(this.credentialsService.getProfile());
    if((profile.roles as []).length > 1){
      this.roles = profile.roles as any[]
    }else{
      this.redirectToRoleHome(profile.role);
    }
  }

  selectRole(role: any){
    let credentials = this.credentialsService.credentials as Credentials;
    credentials.role = role;
    this.credentialsService.setCredentials(credentials, true);
    this.redirectToRoleHome(credentials.role);
  }

  redirectToRoleHome(role: number|undefined) {
    localStorage.setItem('hasSelectedRole', 'true');
    const self = this;
    this.credentialsService.validateRedirect(role as number).then((url) => {
      self.router.navigateByUrl(url);
    });
  }
}
