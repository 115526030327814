import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  constructor(private swUpdate: SwUpdate, private toastr: ToastrService, private translateService: TranslateService) {
    if (!this.swUpdate.isEnabled) {
      console.log('No service worker to update');
    }
    
    const updatesAvailable = this.swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => ({
      type: 'UPDATE_AVAILABLE',
      current: evt.currentVersion,
      available: evt.latestVersion,
      })));

      updatesAvailable.subscribe(update => {
        const toast = this.toastr.info(this.translateService.instant('Update Available'), this.translateService.instant('Reload'));
      
        toast.onHidden.subscribe(() => {});
      })
  }
}